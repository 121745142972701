<template>
    <div class="container">
        <h1>{{ $t('bankAccounts.heading') }}</h1>
        <p>{{$t('bankAccounts.entryText')}}</p>
        <div
            v-if="bankAccounts.length > 0"
            class="mt-5 mb-5"
        >
            <loader-overlay :loading="accountsLoading || bankAccountsSaving">
                <h3>{{$t('bankAccounts.list.heading')}}</h3>
                    <accounts-list
                        :accounts="bankAccounts || []"
                        @edit="onEditAccount"
                        @delete="onDeleteAccount"
                    />
                <div
                    v-if="!showForm"
                    class="mt-4"
                >
                    <b-btn
                        variant="primary"
                        @click.prevent="showForm = true"
                    >
                        <b-icon icon="plus"/> {{$t('bankAccounts.addAccount')}}
                    </b-btn>
                </div>
            </loader-overlay>
        </div>
        <div
            v-if="showForm"
            class="p-4 bg-white border-light rounded shadow"
        >
            <loader-overlay :loading="bankAccountsSaving">
                <bank-account-form
                    :defaults="editedAccount || {}"
                    :show-validation="touchForm"
                    :client-type="clientType"
                    @submit="onSubmitAccount"
                    @cancel="onCancel"
                />
            </loader-overlay>
        </div>
        <form-input-checkbox
            style="line-height: 1.6;"
            class="mt-5"
            :label="$t(`bankAccounts.bankAccountsConfirmedByAffidavit.${clientType === 'PO' ? 'PO' : 'FO'}`)"
            v-model="bankAccountsConfirmedByAffidavit"
            :validation="$v.bankAccountsConfirmedByAffidavit"
        />
    </div>
</template>

<script>
import BankAccountForm from './Components/BankAccountForm.vue'
import AccountsList from './Components/AccountsList.vue'
import ViewBase from '../ViewBase.vue'
import LoaderOverlay from '../../../../Components/LoaderOverlay.vue'

export default {
    name: 'BankAccounts',
    extends: ViewBase,
    components: {
        BankAccountForm,
        AccountsList,
        LoaderOverlay
    },
    data () {
        return {
            showForm: !(this.sessionData && Array.isArray(this.sessionData.bankAccounts) && this.sessionData.bankAccounts.length > 0),
            editedAccount: null,
            accountsLoading: false,
            bankAccountsSaving: false,
            touchForm: false
        }
    },
    computed: {
        bankAccounts () {
            return ((this.sessionData) ? this.sessionData.bankAccounts : []) || []
        },
        bankAccountsConfirmedByAffidavit: {
            get () {
                return this.sessionData?.bankAccountsConfirmedByAffidavit ?? false
            },
            set (value) {
                this.saveSessionData({ bankAccountsConfirmedByAffidavit: value })
            }
        }
    },
    watch: {
        bankAccounts (newValue, oldValue) {
            this.$nextTick(() => {
                if (oldValue.length > 0 && newValue.length === 0) {
                    this.showForm = true
                }
                if (oldValue.length < newValue.length) {
                    this.showForm = false
                }
            })
        }
    },
    mounted () {
        this.$serverValidator.addHandler('bankAccounts', this.onServerValidation)
        this.$store.commit('setFormData', { name: 'bankAccounts', content: this.bankAccounts })
    },
    beforeDestroy () {
        this.$serverValidator.removeHandler('bankAccounts', this.onServerValidation)
        this.touchForm = false
    },
    methods: {
        async onServerValidation (isValid, accountId) {
            this.touchForm = false
            if (!isValid) {
                this.$notify.error(this.$t('errors.someDataIsMissing'))
                const account = this.bankAccounts.find(item => item._id === accountId)
                this.onEditAccount(account || {})
                this.touchForm = true
            }
        },
        async onSubmitAccount (data) {
            this.bankAccountsSaving = true
            const api = this.$api.clientSessionBankAccounts
            try {
                const apiCall = (id, data) => (id) ? api.update(id, data) : api.create(data)
                await apiCall(data._id || null, data)
                this.editedAccount = null
                this.showForm = false
                this.$store.commit('setDataHash', { name: 'bankAccountOld', hash: null })
                this.$store.commit('setDataHash', { name: 'bankAccount', hash: null })
                this.$emit('reload')
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.sessionUpdateFailed'))
            } finally {
                this.$nextTick(() => {
                    this.bankAccountsSaving = false
                })
            }
        },
        onCancel () {
            if (this.bankAccounts.length > 0) {
                this.showForm = false
            }
            this.editedAccount = null
            this.touchForm = false
        },
        onEditAccount (account) {
            this.onCancel()
            this.$nextTick(() => {
                this.editedAccount = { ...account }
                this.showForm = true
            })
        },
        onDeleteAccount (account) {
            this.accountsLoading = true
            this.$api.clientSessionBankAccounts.delete(account._id)
                .then(() => {
                    this.$emit('reload')
                }).catch((error) => {
                    console.error(error.message)
                }).finally(() => {
                    this.$nextTick(() => {
                        this.accountsLoading = false
                    })
                })
        }
    },
    validations: {
        bankAccountsConfirmedByAffidavit: {
            custom: value => value === true
        }
    }
}
</script>
