<template>
    <div class="bank-accounts list-group bg-white shadow">
        <div class="list-group-item"
            v-for="account in accounts"
            :key="account._id"
        >
            <div class="row align-content-center align-items-center">
                <div class="col-12 col-sm-9 col-md-10">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <span class="font-weight-bold text-primary">
                                {{ $t('bankAccounts.list.name') }}
                            </span><br/>
                            {{ account.name }}
                        </div>
                        <div class="col-12 col-md-7" v-if="!account.isForeign">
                            <span class="font-weight-bold text-primary">
                                {{ $t('bankAccounts.list.accountNumber') }}
                            </span><br/>
                            {{ account.accountNumberPrefix }}-{{ account.accountNumber }}/{{ account.bankCode }}
                        </div>
                        <div class="col-12 col-md-5" v-else>
                            <span class="font-weight-bold text-primary">
                                {{ $t('bankAccounts.list.iban') }}
                            </span><br/>
                            {{ account.iban }}
                        </div>
                        <div class="col-12 col-md-2" v-if="account.isForeign">
                            <span class="font-weight-bold text-primary">
                                {{ $t('bankAccounts.list.swift') }}
                            </span><br/>
                            {{ account.swift }}
                        </div>
                        <div class="col-12 col-md-1">
                            <span class="font-weight-bold text-primary">
                                {{ $t('bankAccounts.list.currency') }}
                            </span><br/>
                            {{ account.currency }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3 col-md-2 text-sm-right whitespace-nowrap">
                    <b-btn
                        v-if="canEdit"
                        variant="primary"
                        @click.prevent="onEdit(account)"
                        size="sm"
                    >
                        <b-icon icon="pencil"></b-icon>
                    </b-btn>
                    <b-btn
                        v-if="canDelete"
                        variant="danger"
                        size="sm"
                        @click.prevent="onDelete(account)"
                        class="ml-2"
                    >
                        <b-icon icon="trash"></b-icon>
                    </b-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountsList',
    props: {
        accounts: {
            type: Array,
            required: false,
            default: () => []
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: true
        },
        canDelete: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        onEdit (account) {
            if (this.canEdit) {
                this.$emit('edit', account)
            }
        },
        onDelete (account) {
            if (this.canDelete) {
                this.$swal.warning(
                    this.$t('bankAccounts.list.confirm.title'),
                    this.$t('bankAccounts.list.confirm.text'),
                    this.$t('bankAccounts.list.confirm.delete'),
                    this.$t('bankAccounts.list.confirm.cancel')
                ).then((value) => {
                    if (value === 'ok') {
                        this.$emit('delete', account)
                    }
                }).catch(() => { /* nothing */ })
            }
        }
    }
}
</script>
