<template>
    <div>
        <h3>{{$t(`bankAccounts.form.${id ? 'edit' : 'create'}`)}}</h3>

        <b-form @submit.prevent="onSubmit">
            <div class="mb-2">
                <b-form-checkbox v-model="isForeign">{{ $t('bankAccounts.form.isForeign') }}</b-form-checkbox>
            </div>

            <div class="row">
                <div class="col-12 col-md-8 col-lg-9">
                     <form-input
                        :id="'bankAccount.name'"
                        :label="$t('bankAccounts.form.name')"
                        v-model.trim="name"
                        :validation="$v.name"
                    />
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                     <form-input-select
                        :id="'bankAccount.currency'"
                        :label="$t('bankAccounts.form.currency')"
                        v-model.trim="currency"
                        :options="currencies"
                        :validation="$v.currency"
                    />
                </div>
            </div>
            <div class="row" v-if="!isForeign">
                <div class="col-12 col-md-3">
                    <form-input
                        :id="'bankAccount.accountNumberPrefix'"
                        :label="$t('bankAccounts.form.accountNumberPrefix')"
                        v-model.trim="accountNumberPrefix"
                        :validation="$v.accountNumberPrefix"
                        :formatter="formatBankAccountPrefix"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <form-input
                        :id="'bankAccount.accountNumber'"
                        :label="$t('bankAccounts.form.accountNumber')"
                        v-model.trim="accountNumber"
                        :validation="$v.accountNumber"
                        :formatter="formatBankAccount"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <form-input
                        :id="'bankAccount.bankCode'"
                        :label="$t('bankAccounts.form.bankCode')"
                        v-model.trim="bankCode"
                        :validation="$v.bankCode"
                        :formatter="formatBankCode"
                    />
                </div>
            </div>

            <div class="row" v-else>
                <div class="col-12 col-md-8 col-lg-9">
                    <form-input
                        :id="'bankAccount.iban'"
                        :label="$t('bankAccounts.form.iban')"
                        v-model.trim="iban"
                        :validation="$v.iban"
                        :formatter="formatIban"
                        :validation-messages="{ custom: $t('validation.invalidIBAN') }"
                    />
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <form-input
                        v-if="isForeign"
                        :id="'bankAccount.swift'"
                        :label="$t('bankAccounts.form.swift')"
                        v-model.trim="swift"
                        :validation="$v.swift"
                        :validation-messages="{ custom: $t('validation.invalidSWIFT') }"
                    />
                </div>
            </div>

            <div class="mt-5">
                <b-btn
                    type="submit"
                    variant="primary"
                    class="mr-4"
                >
                    {{ $t('app.submit') }}
                </b-btn>
                <b-btn
                    variant="light"
                    @click.prevent="onCancel"
                >
                    {{ $t('app.cancel') }}
                </b-btn>
            </div>
        </b-form>
    </div>
</template>

<script>
import { required, requiredIf, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import * as objectHash from 'object-hash'
const ibantools = require('ibantools')

export default {
    name: 'BankAccountForm',
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        },
        showValidation: {
            type: Boolean,
            required: true
        },
        clientType: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            ivanId: this.defaults.ivanId || null,
            id: this.defaults._id || null,
            name: this.defaults.name || null,
            accountNumber: this.defaults.accountNumber || null,
            accountNumberPrefix: this.defaults.accountNumberPrefix || null,
            bankCode: this.defaults.bankCode || null,
            iban: this.defaults.iban || null,
            swift: this.defaults.swift || null,
            currency: this.defaults.currency || null,
            isForeign: this.defaults.isForeign || false
        }
    },
    computed: {
        ...mapGetters(['currencies']),
        dataHash () {
            const rest = JSON.parse(JSON.stringify(this.$data))
            return objectHash.sha1(rest)
        }
    },
    watch: {
        defaults (newValue) {
            this.$nextTick(() => {
                this.ivanId = this.defaults.ivanId || null
                this.id = this.defaults._id || null
                this.name = this.defaults.name || null
                this.accountNumber = this.defaults.accountNumber || null
                this.accountNumberPrefix = this.defaults.accountNumberPrefix || null
                this.bankCode = this.defaults.bankCode || null
                this.iban = this.defaults.iban || null
                this.swift = this.defaults.swift || null
                this.currency = this.defaults.currency || null
                this.isForeign = this.defaults.isForeign || false
            })
        },
        dataHash (newValue) {
            this.$store.commit('setDataHash', { name: 'bankAccount', hash: newValue })
        }
    },
    methods: {
        onCancel () {
            this.onReset()
            this.$store.commit('setDataHash', { name: 'bankAccountOld', hash: null })
            this.$store.commit('setDataHash', { name: 'bankAccount', hash: null })
            this.$emit('cancel')
        },
        onReset () {
            this.id = null
            this.name = null
            this.accountNumber = null
            this.accountNumberPrefix = null
            this.bankCode = null
            this.iban = null
            this.swift = null
            this.currency = null
            this.isForeign = false
        },
        onSubmit () {
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    const { id, ...data } = JSON.parse(JSON.stringify(this.$data))
                    data._id = id
                    this.$emit('submit', data)
                }
            })
        },
        formatIban (value) {
            let tmp = value.match(/[a-z0-9]/ig)
            if (tmp === null) {
                return value
            }
            tmp = tmp.join('').toUpperCase()
            return ibantools.friendlyFormatIBAN(tmp)
        },
        formatSwift (value) {
            const tmp = value.match(/[a-z0-9]/ig)
            if (tmp === null) {
                return value
            }
            return tmp.join('').toUpperCase()
        },
        formatBankCode (value) {
            if (value && value.length > 4) {
                const match = value.match(/(^\d{4})/)
                if (match !== null) {
                    return match[0]
                }
            }
            return value.replace(/\D/ig, '')
        },
        formatBankAccount (value) {
            return this.$stringFormat.bankAccount(value)
        },
        formatBankAccountPrefix (value) {
            return this.$stringFormat.bankAccountPrefix(value)
        }
    },
    validations: {
        name: {
            required
        },
        accountNumberPrefix: {
            required: requiredIf((model) => {
                return model.isForeign === false
            }),
            numeric,
            minLength: minLength(6)
        },
        accountNumber: {
            required: requiredIf((model) => {
                return model.isForeign === false
            }),
            numeric,
            minLength: minLength(5),
            maxLength: maxLength(10)
        },
        bankCode: {
            required: requiredIf((model) => {
                return model.isForeign === false
            }),
            numeric,
            minLength: minLength(4),
            maxLength: maxLength(4)
        },
        iban: {
            required: requiredIf((model) => {
                return model.isForeign === true
            }),
            custom (value) {
                const iban = ibantools.electronicFormatIBAN(value)
                return this.isForeign === false || ibantools.isValidIBAN(iban)
            }
        },
        swift: {
            required: requiredIf((model) => {
                return model.isForeign === true
            }),
            custom (value) {
                return this.isForeign === false || ibantools.isValidBIC(value)
            }
        },
        currency: {
            required
        }
    }
}
</script>
